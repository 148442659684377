
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

:root {
  font-family: Georgia, "Times New Roman", Times, serif;
  line-height: 1.5;
  font-weight: 400;
  display: flex;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: block;
  place-items: start;
  min-width: 320px;
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
}

h1 {
  /* font-size: 3.2em; */
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}


#root {
  /* max-width: 1280px; */
  /* margin: 0 auto; */
  padding: 0rem;
  /* text-align: center; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}


body {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #555;
  background-color: #fdfbef;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: normal;
  color: #333;
}


/*
 * Override Bootstrap's default container.
 */

/* .container {
  max-width: 60rem;
} */


/*
 * Masthead for nav
 */

.phsoftware-masthead {
  margin-bottom: 0.1rem;
  background-color: #fced6b;
  -webkit-box-shadow: inset 0 -.1rem .25rem rgba(0,0,0,.1);
          box-shadow: inset 0 -.1rem .25rem rgba(0,0,0,.1);
}

/* Nav links */
.nav-link {
  position: relative;
  padding: 1rem;
  font-weight: 500;
  color: #cdddeb !important;
  background-color: #428bca;
}
.nav-link:hover,
.nav-link:focus {
  color: #fff !important;
  background-color: #428bca;
}

.dropdown-toggle{
  color: #cdddeb;
  background-color: #428bca;
}

/* Nav drop down menu links */
.dropdown-menu {
  background-color: #428bca;
}
.dropdown-item {
  color: #cdddeb;
}

/* Active state gets a caret at the bottom */
.nav-link.active {
  color: #fff;
}
.nav-link.active:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -.3rem;
  vertical-align: middle;
  content: "";
  border-right: .3rem solid transparent;
  border-bottom: .3rem solid;
  border-left: .3rem solid transparent;
}
.affix {
  top: 0;
  width: 100%;
  z-index: 9999 !important;
  background-color: #428bca;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  color: #fff;
  background-color: #428bca;
}

.navbar-toggle-left {
  color: #cdddeb;
}
.navbar-nav > li > a {
    padding-top: 0px;
    padding-bottom: 0px;
}
/*
 * phsoftware name and description
 */

.phsoftware-header {  
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0;
  background: #f3cf0391;
}
.phsoftware-title {
  margin-bottom: 0;
  font-size: 1.5rem !important;
  font-weight: normal;
}
.phsoftware-description {
  padding-top: 6px;
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #999;
}
.phsoftware-sidebar {
  padding-right: 0;
}
.phsoftware-alert {
  padding: 1rem;
  background-color: #fa0505;
  color: white;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
.phsoftware-login-alert {
  padding: 0.2rem;
  background-color: #fa0505;
  color: white;
  border-radius: 0.5rem;
  margin-bottom: 0;
}
.phs-logon {
  padding: 0;
  font-size: .8rem;
}
.phsoftware-emaillist {
  padding: 0px 0px 0px 0px;
  font-size: 15px;
  overflow-y: auto;
  white-space: nowrap;
  height: calc(100vh - 40px - 28px - 52.14px);
  width: 100%;
}

.phsoftware-email {  
  padding: 0px 5px 0px 10px; /*trbl*/
  font-size: 15px;
  height: calc(100vh - 40px - 28px - 52.14px);
  width: 100%;
  overflow-wrap: break-word;
  overscroll-behavior-x: contain;
  overflow-y: auto;
}

.phs_resize {
  resize: horizontal;
  overflow: auto !important;
}
.phs_therest {
  flex:1;
  overflow: auto !important;
  overflow-wrap: break-word;
  overscroll-behavior-x: contain;
  overflow-y: auto;
}

@media (min-width: 40em) {
  .phsoftware-title {
    font-size: 3.5rem;
  }
}


/*
 * Main column and sidebar layout
 */

/* Sidebar modules for boxing content */
.sidebar-module {
  padding: 1rem;
  /*margin: 0 -1rem 1rem;*/
}
.sidebar-module-inset {
  padding: 1rem;
  background-color: #FDF0A0;
  border-radius: .5rem;
}
.sidebar-module-inset p:last-child,
.sidebar-module-inset ul:last-child,
.sidebar-module-inset ol:last-child {
  margin-bottom: 0;
}

/* Pagination */
.phsoftware-pagination {
  margin-bottom: 4rem;
}
.phsoftware-pagination > .btn {
  border-radius: 2rem;
}


/*
 * phsoftware page
 */

.phsoftware-page {
  margin-bottom: 4rem;
  /* background-color: white; */
}
.phsoftware-page-title {
  margin-bottom: .25rem;
  font-size: 2.5rem;
}
.phsoftware-page-meta {
  margin-bottom: 1.25rem;
  color: #999;
}
.row {
  margin-right: 0;
  margin-left: 0;
}
.row>* {
  padding-left: 0;
  padding-right: 0;
}

/*
 * Footer
 */

.phsoftware-footer {
  position: fixed;
  bottom: 0;
  height:20px;
  padding: 0 0;
  color: #999;
  text-align: center;
  background-color: #FDF0A0;
  /* border-top: .05rem solid #e5e5e5; */
}
.phsoftware-footer p:last-child {
  margin-bottom: 0;
}

#flash-message {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  animation: flash-message 4s forwards;
}
@keyframes flash-message {
    0%   {opacity: 1;}
    80%  {opacity: 1;}
    100% {opacity: 0; display:none;}
}

.modal-dialog {
width: 80%;
}

.modal {
--bs-modal-width: 100%;
}


.pagination {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
}

.form-control {
  padding:0;
}

.btn {
  --bs-btn-padding-x:0;
  --bs-btn-padding-y:0;  
  --bs-btn-color:black;
  --bs-btn-bg: #ffd10085 ;  
}

hr {
  margin: 0.4rem;
}

p {
  padding: 0px 0px 0px 0px;
  margin-bottom: 0px;
}
.highlight {
  background-color: #f5e76a;
}

.grecaptcha-badge {visibility: hidden !important;}

.smaller-font {
  font-size: 70%;
}